import React from "react";
import { heroImage, playIcon } from "../../assets";

const Hero = () => {
  const textArray = ["Einfache Buchung", "Experten", "Zuverlässiger Service"];

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex xl:items-center flex-col xl:flex-row justify-center mt-8 sm:mt-14 gap-3 xl:gap-0 xl:mt-0 min-h-[calc(100vh-100px)] ">
      <div className="flex flex-col gap-2 sm:gap-5 w-full lg:w-[900px]">
        <h1 className="text-4xl text-transparent font-pp sm:text-5xl md:text-6xl bg-homeTextGradient bg-clip-text">
          <span className="text-black"> Sprachbarrieren beseitigen,</span> lokal
          und global vernetzen
        </h1>
        <p className="text-[#999999] sm:text-lg  ">
          Finden Sie qualifizierte Dolmetscher. Buchen Sie unkompliziert und
          sicher. Unsere Plattform erleichtert die Terminplanung, spart Zeit und
          vermeidet Missverständnisse.
        </p>

        <div className="flex flex-wrap items-center gap-4 mt-4">
          {textArray.map((textArray, index) => (
            <div
              className="bg-[#427EFE] bg-opacity-10 rounded-lg  px-3 sm:px-4 py-2 sm:py-3 w-fit"
              key={index}
            >
              <p className="text-transparent bg-homeTextGradient bg-clip-text ">
                {textArray}
              </p>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-4 mt-4 sm:flex-row sm:items-center">
          <a href="/login">
            <button className="rounded-lg bg-homeTextGradient w-full sm:w-[251.96px] h-[55px] sm:h-[70px] text-white text-lg sm:text-xl ">
              Anmelden
            </button>
          </a>
          <button
            className="rounded-lg bg-transparent border border-black w-full sm:w-[251.96px] h-[55px] sm:h-[70px] text-black text-lg sm:text-xl  flex items-center justify-center gap-2"
            onClick={() => scrollToSection("videoDolmetschen")}
          >
            <img src={playIcon} alt="Play Icon" />
            Video-Dolmetschen
          </button>
        </div>
      </div>

      <div>
        <img src={heroImage} alt="Hero" />
      </div>
    </div>
  );
};

export default Hero;
